import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { feathers } from '@webfx/core-web';
import { useLocation } from 'react-router-dom';
import useAuth from './useAuth';
import useFlags from './useFlags';

const routeModuleMap = {
  marketingcloudfx: 'mcfx',
  'client-permissions': 'iam',
  contentgenius: 'contentgenius',
  playbookfx: 'playbook',
  nutshell: 'nutshell',
  teamai: 'teamai',
  'help-center': 'helpcenter',
};

export function useMeasure(tools) {
  const { user, isAdmin } = useAuth();
  const { pathname } = useLocation();
  const flags = useFlags();

  useEffect(() => {
    switch (tools) {
      case 'pendo':
        setPendoUser(user, isAdmin, flags);
        break;
      case 'datadog':
        setRumUser(user, isAdmin);
        break;
      default:
        setPendoUser(user, isAdmin, flags);
        setRumUser(user, isAdmin);
    }
  }, [user, tools]);

  useEffect(() => {
    if (pathname) {
      adjustRumContext(pathname, isAdmin);
    }
  }, [pathname, isAdmin]);

  useEffect(() => {
    setRumUser(user, isAdmin);
  }, [user, isAdmin]);
}

export function initializeDatadog() {
  if (window.Cypress) {
    return;
  }

  datadogRum.init({
    applicationId: '6b21282a-202f-4fff-8a41-93d26135e559',
    clientToken: 'pub0d149f2058bcf2083a1b384dca713be2',
    site: 'datadoghq.com',
    service: `mcfx-v5`,
    version: process.env.POI_APP_BUILD_VERSION || `0.0.1-${process.env.NODE_ENV}`,
    env: process.env.POI_APP_BUILD_ENV || process.env.NODE_ENV || 'development',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [/https:\/\/.*\.webfx\.com/],
    beforeSend: (event) => {
      if (
        event.type === 'error' &&
        (event.error.message.toLowerCase().includes('illegal invocation') ||
          event.error.type === 'Forbidden' ||
          event.error.type === 'MethodNotAllowed')
      ) {
        return false;
      }
    },
  });
}

function adjustRumContext(pathname) {
  if (window.Cypress) {
    return;
  }

  if (!pathname) {
    datadogRum.removeGlobalContextProperty('module');
    datadogRum.removeGlobalContextProperty('product');
    datadogRum.removeGlobalContextProperty('feature');
    return;
  }

  const segments = pathname.split('/').filter((s) => s);
  const mod = routeModuleMap[segments[0]] || 'twfx';

  datadogRum.setGlobalContextProperty('module', mod);
  datadogRum.setGlobalContextProperty('product', mod);

  if (mod === 'mcfx' && segments[2]) {
    datadogRum.setGlobalContextProperty('feature', segments[2]);
  } else {
    datadogRum.removeGlobalContextProperty('feature');
  }
}

function setRumUser(user, isAdmin) {
  if (window.Cypress) {
    return;
  }
  if (user) {
    datadogRum.setUser({
      id: user.userId,
      name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
      email: user.email,
      type: user.type,
      isAdmin,
    });
    datadogRum.setGlobalContextProperty('userId', user.userId);
  }
  datadogRum.setGlobalContextProperty('isAdmin', isAdmin);
}

async function setPendoUser(user, isAdmin, flags = {}) {
  if (window.Cypress) {
    return;
  }

  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  if (!window.pendo) {
    return;
  }

  const isGuest = (!user && !isAdmin) || user.userId === 3;
  if (isGuest) {
    if (window?.pendo?.isReady) {
      return;
    }

    await window.pendo.initialize({
      visitor: {},
      account: {},
    });

    return;
  }

  let accountData = {};
  try {
    accountData = await feathers().service(`/users/${user.userId}/tracking-info`).find();
  } catch (e) {
    console.error(e);
  }

  if (!window.pendo.isReady?.()) {
    window.pendo.initialize({
      visitor: {
        app_build_number: process.env.POI_APP_BB_BUILD_NUMBER,
        app_version:
          typeof window.FX_APP_VERSION !== 'object' ? window.FX_APP_VERSION : 'development',
        ...accountData.user,
        ...flags,
      },
      account: accountData.account,
    });
  } else {
    window.pendo.updateOptions({
      visitor: {
        app_build_number: process.env.POI_APP_BB_BUILD_NUMBER,
        app_version:
          typeof window.FX_APP_VERSION !== 'object' ? window.FX_APP_VERSION : 'development',
        ...accountData.user,
        ...flags,
      },
      account: accountData.account,
    });
  }
}
